import React from "react"

import SEO from "../components/seo"
import Main from "../components/main/main"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Main>
      <div className="not-found">
        <h1 >NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Main>
  </>
)

export default NotFoundPage
